/* @import url("https://rsms.me/inter/inter.css"); */

body {
    --main-color: rgb(0, 188, 154);
    --main-light-color: rgba(0, 188, 154, 0.1);
    --text-color: rgba(0, 0, 0, 0.7);
    --gray-color: rgb(169, 169, 169);
    --black: rgb(0, 0, 0);
    --light-gray-color: #c4c4c4;
    --blue-color: #6f62ff;
    --orange-color: #fb9e12;
    --orange-light-color: rgb(255, 252, 245);
    --bg-color: #f9fbfc;
    --bg-promo: #F2F2F2;
    --white-color: #ffffff;
    --main-light-color: rgb(0, 188, 154, 0.2);
    --black-light-color: rgb(0, 0, 0, 0.1);
    --red-color: rgba(239, 83, 80, 1);
    --tariff-name-color: rgba(0, 0, 0, 0.37);
    --sidebar-gradient: linear-gradient(
        180deg,
        rgba(0, 188, 154, 0.2) -17.18%,
        rgba(0, 188, 154, 0) 70.65%
    ),
    #1a1a1a;
    --text-primary: rgba(0, 0, 0, 0.87);
    --text-secondary: rgba(0, 0, 0, 0.6);
    --text-third: rgba(0, 0, 0, 0.37);

    --bg-element-hover-hover: var(--light-gray-color);

    padding: 0;
    margin: 0;
    min-height: 100vh;
    text-align: center;
    font-family: "Inter", "Apercu Pro", sans-serif;
    overflow-x: hidden;
    font-size: 16px;

    --switcher-background: rgba(167, 167, 167, 0.2);
    --switcher-button-background: white;

}
